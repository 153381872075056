import { throttle } from "throttle-debounce";
import { Accordion, Collapse, Tooltip } from "bootstrap";
import Carousel from "components/carousel/index";
import HeroSwiper from "components/iamm-hero-swiper/index";
import Artwork from "components/pt-artwork/index";
import ActiveMenuItem from "components/pt-menu-item/index";
import Fact from "components/fact/index";
import FilterByLetter from "components/pt-artists-list/index";
// import Form from "components/advanced-search/index";
import Datepick from "components/date-picker/index";
import Dropdown from "components/dropdown/index";
import Input from "components/form/index";
import Chip from "components/chip/index";
import Filter from "components/filter/index";
import RangePicker from "components/range-picker/index";
import ThemeScripts from "./theme-scripts";

export default class Global {
  initOnDOMContentLoaded() {
    new Carousel().init();
    new HeroSwiper().init();
    new Artwork().init();
    new ActiveMenuItem().init();
    new Fact().init();
    // new Form().init()
    new FilterByLetter().init();
    new Datepick().init();
    new Dropdown().init();
    new Input().init();
    new Chip().init();
    new Filter().init();
    new RangePicker().init();
    new ThemeScripts().init();

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }

  initOnWindowload() {}
}
