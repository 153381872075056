import Swiper, { Autoplay, EffectFade } from "swiper";
Swiper.use([Autoplay, EffectFade]);

export default class HeroSwiper {
  init() {
    const elements = document.querySelectorAll(".HeroSlider");

    elements.forEach((element) => {
      this.swiper = new Swiper(element, {
        slidesPerView: 1,
        effect: "fade",
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        speed: 1000,
        allowTouchMove: false,
        on: {
          slideChangeTransitionStart: function () {
            const activeSlide = this.slides[this.activeIndex];
            const previousSlide = this.slides[this.previousIndex];
            const newAnimatedWord = activeSlide.querySelector(".animated-word");
            const oldAnimatedWord =
              previousSlide.querySelector(".animated-word");

            if (oldAnimatedWord) {
              oldAnimatedWord.classList.add("slide-up-out");
            }

            if (newAnimatedWord) {
              newAnimatedWord.classList.remove("slide-up-out");
              newAnimatedWord.classList.remove("animated-word");

              void newAnimatedWord.offsetWidth;

              newAnimatedWord.classList.add("animated-word");
            }
          },
        },
      });
    });
  }
}
