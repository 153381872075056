export default class ThemeScripts {
  init() {
    //
    // Main menu button on click
    //
    const mainMenuBtn = document.getElementById("main-menu-btn");
    const mainMenu = document.getElementById("main-menu");
    const menuBtnText = document.getElementById("menu-btn-text");
    const closeMenu = document.getElementById("close-menu");

    if (mainMenuBtn) {
      mainMenuBtn.addEventListener("click", () => {
        mainMenu.classList.toggle("show-menu");
        menuBtnText.classList.toggle("hide-text");
        closeMenu.classList.toggle("show-icon");
      });
    }

    //
    // Check scroll direction
    //
    const mainLogoWrapper =
      document.getElementsByClassName("main-logo-wrapper")[0];
    var lastScrollTop = 0;
    var scrollLimit;
    var scrollLimitOff;

    window.addEventListener(
      "scroll",
      function () {
        if (window.innerWidth < 768) {
          scrollLimit = 98;
          scrollLimitOff = 99;
        } else {
          scrollLimit = 130;
          scrollLimitOff = 131;
        }
        var st = window.scrollY || document.documentElement.scrollTop;
        if (
          st > lastScrollTop &&
          (document.body.scrollTop > scrollLimit ||
            document.documentElement.scrollTop > scrollLimit)
        ) {
          // downscroll code
          // console.log("downscroll");
          mainLogoWrapper.classList.add("hide-logo");
        } else if (
          st < lastScrollTop &&
          (document.body.scrollTop > 10 ||
            document.documentElement.scrollTop > 10)
        ) {
          // upscroll code
          // console.log("upscroll");
          mainLogoWrapper.classList.remove("hide-logo");
        } // else was horizontal scroll
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );

    ///////////////////
    // Photo modal
    ///////////////////
    var photoModal = document.getElementById("myPhotoModal");
    var openPhotoModal = document.getElementsByClassName("enlarge-image");
    var closePhotoModal =
      document.getElementsByClassName("close-photo-modal")[0];
    var photoModalImg = document.getElementById("photo-modal-img");
    var photoModalContent = document.getElementsByClassName("modal-content")[0];
    var photoModalCaption = document.getElementById("modal-caption");
    var nextButton = document.getElementsByClassName("next-button")[0];
    var previousButton = document.getElementsByClassName("previous-button")[0];
    var scrollStyle = document.getElementsByClassName("scroll-style")[0];

    var positionElem,
      nextPositionElem,
      nextElem,
      previousPositionElem,
      previousElem;

    function updateCaption(elem) {
      if (photoModalCaption) {
        let captionElem = null;
        if (elem.nextElementSibling?.classList.contains("quirky-caption")) {
          captionElem = elem.nextElementSibling;
        } else if (
          elem.parentElement?.nextElementSibling?.firstElementChild?.classList.contains(
            "image-caption"
          )
        ) {
          captionElem = elem.parentElement.nextElementSibling.firstElementChild;
        } else if (
          elem.nextElementSibling?.classList.contains(
            "two-columns-image-caption"
          )
        ) {
          captionElem = elem.nextElementSibling;
        }

        if (captionElem) {
          photoModalCaption.innerHTML = captionElem.innerHTML;
          photoModalContent.classList.add("includes-caption");
        } else {
          photoModalCaption.innerHTML = "";
          photoModalContent.classList.remove("includes-caption");
        }
      }
    }

    function showModal(elem) {
      positionElem = Array.from(openPhotoModal).indexOf(elem);
      nextPositionElem = positionElem + 1;
      previousPositionElem = positionElem - 1;

      nextElem = openPhotoModal[nextPositionElem] || null;
      previousElem = openPhotoModal[previousPositionElem] || null;

      photoModalImg.src = elem.src;
      photoModalImg.alt = elem.alt;
      photoModal.style.display = "flex";
      scrollStyle.classList.add("scroll-total-stop");

      nextButton.classList.toggle("show-next-button", !!nextElem);
      previousButton.classList.toggle("show-previous-button", !!previousElem);

      updateCaption(elem);
    }

    function handleNavigation(direction) {
      let targetElem = direction === "next" ? nextElem : previousElem;

      if (targetElem) {
        photoModalImg.src = targetElem.src;
        photoModalImg.alt = targetElem.alt;
        updateCaption(targetElem);

        positionElem += direction === "next" ? 1 : -1;
        nextElem = openPhotoModal[positionElem + 1] || null;
        previousElem = openPhotoModal[positionElem - 1] || null;

        nextButton.classList.toggle("show-next-button", !!nextElem);
        previousButton.classList.toggle("show-previous-button", !!previousElem);
      }
    }

    if (openPhotoModal) {
      Array.from(openPhotoModal).forEach((elem) => {
        elem.addEventListener("click", () => showModal(elem));
      });
    }

    if (nextButton) {
      nextButton.addEventListener("click", () => handleNavigation("next"));
    }

    if (previousButton) {
      previousButton.addEventListener("click", () =>
        handleNavigation("previous")
      );
    }

    if (closePhotoModal) {
      closePhotoModal.addEventListener("click", () => {
        positionElem =
          nextPositionElem =
          nextElem =
          previousPositionElem =
          previousElem =
            null;
        photoModal.style.display = "none";
        scrollStyle.classList.remove("scroll-total-stop");
        nextButton.classList.remove("show-next-button");
        previousButton.classList.remove("show-previous-button");
      });
    }

    //////////////////////////////
    // Choices accessibility fixes
    //////////////////////////////
    const dropdownChoices = document.querySelectorAll(".choices");
    const dropdownChoices2 = document.querySelectorAll(
      ".choices__inner > .choices__list--single.choices__list > .choices__placeholder"
    );

    if (dropdownChoices) {
      Array.from(dropdownChoices).forEach((elem) => {
        elem.setAttribute("aria-label", "dropdown");
        elem.removeAttribute("aria-expanded");
        elem.removeAttribute("role");
      });
    }

    if (dropdownChoices2) {
      Array.from(dropdownChoices2).forEach((elem) => {
        elem.removeAttribute("aria-selected");
      });
    }

    //////////////////////////////
    // Work archive filters
    //////////////////////////////
    // const desktopFilters = document.querySelector(".desktop-filters");
    // const desktopFiltersItems = document.querySelectorAll(
    //   ".desktop-filters .item"
    // );

    // // Handle Main Filter changes
    // if (desktopFilters && desktopFiltersItems) {
    //   // console.log("Handle Main Filter changes");
    //   desktopFiltersItems.forEach(function (filter) {
    //     filter.addEventListener("click", function () {
    //       let onesector = this.getAttribute("data-sector");

    //       // Get the current URL
    //       let url = new URL(window.location.href);

    //       // Set or remove the sector filter in the URL
    //       if (onesector) {
    //         url.searchParams.set("sector", onesector); // Add or update 'sector'
    //         url.searchParams.delete("subcategory"); // Remove subcategory filter
    //       } else {
    //         url.searchParams.delete("sector"); // Remove sector filter for "All"
    //         url.searchParams.delete("subcategory"); // Remove subcategory filter
    //       }

    //       // Apply the new URL
    //       window.location.href = url.toString();
    //     });
    //   });
    // }

    // // Add Active Class to the Filter
    // window.addEventListener("DOMContentLoaded", function () {
    //   const subcategoryDropdown = document.querySelector(
    //     'select[name="subcategory"]'
    //   );
    //   const subcategoryDropdownChoices = subcategoryDropdown
    //     ? subcategoryDropdown.parentElement.parentElement
    //     : null;

    //   if (desktopFilters) {
    //     let url = new URL(window.location.href);
    //     let currentSector = url.searchParams.get("sector");

    //     if (desktopFiltersItems) {
    //       desktopFiltersItems.forEach(function (filter) {
    //         let sector = filter.getAttribute("data-sector");

    //         // If the filter matches the current sector or all, mark it active
    //         if (
    //           (sector && sector === currentSector) ||
    //           (!sector && !currentSector)
    //         ) {
    //           filter.classList.add("selected");
    //         } else {
    //           filter.classList.remove("selected");
    //         }
    //       });
    //     }

    //     // Hide the subcategory dropdown if no sector is selected
    //     if (subcategoryDropdownChoices) {
    //       if (!currentSector) {
    //         subcategoryDropdownChoices.classList.add("subcat-hide");
    //         subcategoryDropdownChoices.classList.remove("subcat-show");
    //       } else {
    //         subcategoryDropdownChoices.classList.add("subcat-show");
    //         subcategoryDropdownChoices.classList.remove("subcat-hide");
    //       }
    //     }
    //   }
    // });
  }
}
