import Datepicker from "../../../node_modules/vanillajs-datepicker/js/Datepicker";
import DateRangePicker from "../../../node_modules/vanillajs-datepicker/js/DateRangePicker.js";

export default class Datepick {
  init() {
    const elems = document.querySelectorAll('input[name="foo"]');
    elems.forEach(function (elem) {
      const datepicker = new Datepicker(elem, {
        todayButton: true,
        prevArrow: "🡰",
        nextArrow: "🡲",
        format: "dd/mm/yyyy",
      });
    });

    const elements = document.querySelectorAll("#foo");
    elements.forEach(function (element) {
      const rangepicker = new DateRangePicker(element, {
        todayButton: true,
        prevArrow: "",
        nextArrow: "",
        format: "dd/mm/yyyy",
      });
    });
  }
}
