import Global from "./global";

const global = new Global();

document.addEventListener("DOMContentLoaded", () => {
  global.initOnDOMContentLoaded();
});

window.addEventListener("load", () => {
  global.initOnWindowload();
});
