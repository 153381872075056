import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

export default class Carousel {
  init() {
    var windowSize = window.matchMedia("(max-width: 940px)");
    const elements = document.querySelectorAll(".ImageGrid, .Carousel");

    document.addEventListener("DOMContentLoaded", function () {});
    elements.forEach((element) => {
      const nextArrow = element.querySelector(".Carousel__nav-next");
      const prevArrow = element.querySelector(".Carousel__nav-prev");
      const pagination = element.querySelector(".Carousel__pagination");
      const view = parseInt(element.getAttribute("data-view"), 10) || 1;

      this.swiper = new Swiper(element, {
        slidesPerView: 1,
        loop: false,
        breakpoints: {
          768: {
            slidesPerView: view,
            spaceBetween: 0,
          },
        },
        navigation: {
          nextEl: nextArrow,
          prevEl: prevArrow,
        },
        pagination: {
          el: pagination,
          clickable: true,
        },
      });
    });
  }
}
