export default class Filter {
  init() {
    //////////////////////////////
    // Work archive filters and dropdowns
    //////////////////////////////
    window.addEventListener("DOMContentLoaded", function () {
      const dropdownOptions = document.getElementById("dropdown-options");
      const searchParams = new URLSearchParams(window.location.search);
      const dropdowns = document.querySelectorAll(".Dropdown");
      const desktopFilters = document.querySelector(".desktop-filters");
      const desktopFiltersItems = document.querySelectorAll(
        ".desktop-filters .item"
      );
      const mobileBtn = document.querySelector(".mobile-btn");
      const choicesMob = document.querySelectorAll(
        "#dropdown-options .choices"
      );
      const dropdownOptionsMobile = document.getElementById(
        "dropdown-options-mob"
      );
      const dropdownOptionsMobileSelect = document.querySelectorAll(
        ".choices-dropdown-mob select"
      );
      const subcategoryDropdown = document.querySelector(
        'select[name="subcategory"]'
      );
      const search = document.querySelector('input[name="search"]');
      const closeIcon = document.querySelector(".close-icon");
      const searchIcon = document.querySelector(".search-icon");

      //////////////////////////////
      // Helper function to reset pagination in URL path
      //////////////////////////////
      const resetPaginationInUrl = () => {
        let url = window.location.pathname;
        // Remove `/page/X/` segment from URL path
        url = url.replace(/\/page\/\d+\//, "/");
        return url;
      };

      //////////////////////////////
      // Handle Dropdown Filter Changes
      //////////////////////////////
      if (dropdowns) {
        dropdowns.forEach((dropdown) => {
          dropdown.addEventListener("change", () => {
            const selectedValue = dropdown.value;
            const fieldName = dropdown.getAttribute("fieldname");

            if (selectedValue) {
              searchParams.set(
                fieldName,
                decodeURIComponent(encodeURIComponent(selectedValue))
              );
            } else {
              searchParams.delete(fieldName);
            }

            const newUrl = `${resetPaginationInUrl()}?${searchParams.toString()}`;
            window.history.pushState({}, "", newUrl);
            dropdownOptions.submit();
          });
        });
      }

      //////////////////////////////
      // Handle Mobile Filter Toggle
      //////////////////////////////
      if (mobileBtn && choicesMob) {
        mobileBtn.addEventListener("click", (event) => {
          event.preventDefault();
          mobileBtn.classList.toggle("onclick");
          choicesMob.forEach((choice) => {
            choice.classList.toggle("show-dropdown");
          });
        });
      }

      //////////////////////////////
      // Handle Mobile Dropdown Filter Changes
      //////////////////////////////
      if (dropdownOptionsMobile && dropdownOptionsMobileSelect) {
        dropdownOptionsMobileSelect.forEach((dropdown) => {
          dropdown.addEventListener("change", () => {
            const selectedValue = dropdown.value;
            const fieldName = dropdown.getAttribute("name");

            if (selectedValue) {
              searchParams.set(
                fieldName,
                decodeURIComponent(encodeURIComponent(selectedValue))
              );
            } else {
              searchParams.delete(fieldName);
            }

            const newUrl = `${resetPaginationInUrl()}?${searchParams.toString()}`;
            window.history.pushState({}, "", newUrl);
            dropdownOptionsMobile.submit();
          });
        });
      }

      //////////////////////////////
      // Handle Subcategory Dropdown Filter Changes
      //////////////////////////////
      subcategoryDropdown?.addEventListener("change", () => {
        const selectedValue = subcategoryDropdown.value;
        if (selectedValue) {
          searchParams.set(
            "subcategory",
            decodeURIComponent(encodeURIComponent(selectedValue))
          );
        } else {
          searchParams.delete("subcategory");
        }

        const newUrl = `${resetPaginationInUrl()}?${searchParams.toString()}`;
        window.history.pushState({}, "", newUrl);
        dropdownOptions.submit();
      });

      //////////////////////////////
      // Handle Desktop Main Filter Changes
      //////////////////////////////
      if (desktopFilters && desktopFiltersItems) {
        desktopFiltersItems.forEach((filter) => {
          filter.addEventListener("click", () => {
            let onesector = filter.getAttribute("data-sector");
            let url = new URL(window.location.href);

            if (onesector) {
              url.searchParams.set("sector", onesector);
              url.searchParams.delete("subcategory");
            } else {
              url.searchParams.delete("sector");
              url.searchParams.delete("subcategory");
            }

            const newUrl = `${resetPaginationInUrl()}?${url.searchParams.toString()}`;
            window.location.href = newUrl;
          });
        });
      }

      //////////////////////////////
      // Add Active Class to Filter on Page Load
      //////////////////////////////
      const subcategoryDropdownChoices = subcategoryDropdown
        ? subcategoryDropdown.parentElement.parentElement
        : null;
      let currentSector = searchParams.get("sector");

      if (desktopFiltersItems) {
        desktopFiltersItems.forEach((filter) => {
          let sector = filter.getAttribute("data-sector");

          if (
            (sector && sector === currentSector) ||
            (!sector && !currentSector)
          ) {
            filter.classList.add("selected");
          } else {
            filter.classList.remove("selected");
          }
        });
      }

      // Hide the subcategory dropdown if no sector is selected
      if (subcategoryDropdownChoices) {
        if (!currentSector) {
          subcategoryDropdownChoices.classList.add("subcat-hide");
          subcategoryDropdownChoices.classList.remove("subcat-show");
        } else {
          subcategoryDropdownChoices.classList.add("subcat-show");
          subcategoryDropdownChoices.classList.remove("subcat-hide");
        }
      }

      //////////////////////////////
      // Hide After-Icon on Selected Choices
      //////////////////////////////
      const allChoices = document.querySelectorAll(
        ".choices .choices__list--single"
      );
      if (allChoices) {
        allChoices.forEach((choice) => {
          const choiceFirstChild = choice.firstElementChild;

          if (
            choiceFirstChild &&
            choiceFirstChild.getAttribute("data-value") !== ""
          ) {
            choice.parentElement.parentElement.classList.add("hide-after");
          } else {
            choice.parentElement.parentElement.classList.remove("hide-after");
          }
        });
      }

      //////////////////////////////
      // Handle Search Function
      //////////////////////////////
      if (search && closeIcon) {
        search.addEventListener("keypress", (event) => {
          if (event.key === "Enter") {
            const newUrl = `${resetPaginationInUrl()}?${searchParams.toString()}`;
            window.history.pushState({}, "", newUrl);
            dropdownOptions.submit();
          }
        });

        closeIcon.addEventListener("click", () => {
          if (search.value) {
            search.value = "";
            const url = new URL(window.location.href);
            url.searchParams.delete("search");
            const newUrl = `${resetPaginationInUrl()}?${url.searchParams.toString()}`;
            window.location.href = newUrl;
          }
        });
      }

      if (searchIcon) {
        searchIcon.addEventListener("click", function () {
          const newUrl = `${resetPaginationInUrl()}?${searchParams.toString()}`;
          window.history.pushState({}, "", newUrl);
          this.closest("form").submit();
        });
      }
    });
  }
}
